import {Component, OnInit} from '@angular/core';
import { BrandingService } from '../../../core/services/branding.service';
import {Observable} from 'rxjs';
import {AuthService} from '../../../core/services';
import {map, take} from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerLogo$: Observable<string> = this.brandingService.footerLogo$;
  footerLogoUrl;
  footerSecondLogo$: Observable<string> = this.brandingService.footerSecondLogo$;
  footerSecondLogoUrl;
  headerLogo$: Observable<string> = this.brandingService.headerLogo$;
  headerLogoUrl;
  headerSecondLogo$: Observable<string> = this.brandingService.headerSecondLogo$;
  headerSecondLogoUrl;
  footerCopyrightHtml$ = this.brandingService.footerCopyrightHtml$;
  socialLinks$ = this.brandingService.socialLinks$;

  isUserLogin$: Observable<boolean>;
  isContactUsPage$: Observable<boolean>;

  constructor(private brandingService: BrandingService,
              private authService: AuthService) {}

  ngOnInit() {
    this.isUserLogin$ = this.authService.isAuthenticated$;
    this.isContactUsPage$ = this.brandingService.getPageByTitle('contact_us').pipe(
      map(res => !!res)
    );

    this.footerLogo$.pipe(
      take(1)
    ).subscribe(footerUrl => {
      this.footerLogoUrl = footerUrl;
    });

    this.footerSecondLogo$.pipe(
      take(1)
    ).subscribe(footerUrl => {
      this.footerSecondLogoUrl = footerUrl;
    });

    this.headerLogo$.pipe(
      take(1)
    ).subscribe(headerUrl => {
      this.headerLogoUrl = headerUrl;
    });

    this.headerSecondLogo$.pipe(
      take(1)
    ).subscribe(headerUrl => {
      this.headerSecondLogoUrl = headerUrl;
    });
  }

  get isFooterLogo() {
    return this.footerLogoUrl;
  }

  get isSecondFooterLogo() {
    return this.footerLogoUrl && this.footerSecondLogoUrl;
  }

  get isSecondHeaderLogo() {
    return this.headerLogoUrl && this.headerSecondLogoUrl;
  }
}
