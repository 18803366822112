<ng-container *ngIf="(pmaUploadH1$ | async) as pmaUploadH1">
  <div class="tenant-boarding__header tenant-boarding__header_small"
       *ngIf="pmaUploadH1 !== ''"
       [innerHTML]="pmaUploadH1 | safeHtml"
  ></div>
</ng-container>

<div class="tenant-boarding__wrap">
  <div *ngIf="isGetQuote" class="tenant-boarding__wrap-get-renters-quote">
    <div class="tenant-boarding__get-renters-quote">
      <p>Get a renters insurance quote in just a few clicks</p>
      <button mat-button
              type="button"
              class="mat-btn tenant-btn"
              [style.backgroundColor]="quoteButtonColor$ | async"
              (click)="navigateToRentersInsurance()">
        Get Quote Now
      </button>
    </div>
  </div>

  <div class="tenant-boarding__cloud-container">
    <ng-container *ngIf="(pmaUploadDescription$ | async) as pmaUploadDescription">
      <div class="tenant-boarding__text tenant-boarding-partnered-text"
           *ngIf="pmaUploadDescription !== ''"
           [innerHTML]="pmaUploadDescription | safeHtml">
      </div>
    </ng-container>

    <form class="tenant-boarding__form" [formGroup]="form" (ngSubmit)="submitForm()">
      <p class="section-title color_primary">
        <ng-container *ngIf="(editFirstFormValues$ | async) === false && (firstFormComplete$ | async); else titleTmpl">
          Enter Your Insurance Policy Number
        </ng-container>
        <ng-template #titleTmpl>
          {{ "tenant_boarding.policy_email_title" | translate }}
        </ng-template>
      </p>
      <div class="pma-row grid">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'tenant_boarding.policy_number_label' | translate }}</mat-label>
          <input matInput type="text" formControlName="number" required>
          <mat-error *ngIf="controls.number.errors" class="tight">{{ controls.number | errorMsg }}</mat-error>
          <mat-hint *ngIf="verifyingLeaseOrPolicy$ | async">Checking policy number...</mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="displayFirstFormFields$ | async" appearance="outline">
          <mat-label>{{ 'tenant_boarding.email_label' | translate }}</mat-label>
          <input matInput type="text" formControlName="email" required>
          <mat-error *ngIf="controls.email.errors" class="tight">{{ controls.email | errorMsg }}</mat-error>
        </mat-form-field>
      </div>
      <div  *ngIf="!fromToken && (editFirstFormValues$ | async) === false && (firstFormComplete$ | async)" class="previous-information">
        <button *ngIf="!!form.controls.number.value?.trim()" type="button" (click)="editFirstFormValues$.next(true)">Edit</button>
        Your information:
        <div>
          Name:
          <span class="previous-information-value">
            {{ firstForm.controls.first_name.value }}
            {{ firstForm.controls.middle_name.value }}
            {{ firstForm.controls.last_name.value }}
          </span>
        </div>
        <div>
          Email:
          <span class="previous-information-value">{{ firstForm.controls.email.value }}</span>
        </div>
        <div>
          State:
          <span class="previous-information-value">{{ state }}</span>
        </div>
        <div *ngIf="communityName">
          Community:
          <span class="previous-information-value">{{ communityName }}</span>
        </div>
        <div *ngIf="firstForm.controls.address?.value">
          Address:
          <span class="previous-information-value">{{ firstForm.controls.address.value }}</span>
        </div>
        <div *ngIf="buildingName">
          Building:
          <span class="previous-information-value">{{ buildingName }}</span>
        </div>
        <div *ngIf="unitName">Unit:
          <span class="previous-information-value">{{ unitName ?? 'N/A' }}</span>
        </div>
      </div>
      <div *ngIf="(canUpload$ | async) === true">
        <div *ngIf="displayFirstFormFields$ | async" class="pma-row grid names">
          <ng-container *ngIf="(policyBelongsToOtherUser$ | async) !== true">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'user.first_name' | translate }}</mat-label>
              <input matInput type="text" formControlName="first_name" required>
              <mat-error *ngIf="controls.first_name.errors" class="tight">{{ controls.first_name | errorMsg }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'user.middle_name' | translate }}</mat-label>
              <input matInput type="text" formControlName="middle_name">
              <mat-error *ngIf="controls.middle_name.errors" class="tight">{{ controls.middle_name | errorMsg }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'user.last_name' | translate }}</mat-label>
              <input matInput type="text" formControlName="last_name" required>
              <mat-error *ngIf="controls.last_name.errors" class="tight">
                {{ controls.last_name | errorMsg }}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>

        <div *ngIf="showInsurableForm$ | async">
          <ui-insurables
            [progressiveRendering]="true"
            [nonpreferredAddressSelection]="nonpreferredAddressSelection"
            [pmaSingleHouses]="pmaSingleHouses"
            [form]="firstForm"
            [nonApplicableControl]="nonApplicableControl"
            [communityControl]="communityControl"
            [buildingControl]="buildingControl"
            [buildingAddressControl]="firstForm.get('building_address')"
            [buildingAddressCheckbox]="firstForm.get('building_address_checkbox')"
            (setFullAddress)="setFullAddress($event)"
            (setAddressAttributes)="setUserAddressAttributes($event)"
          ></ui-insurables>
          <pm-account *ngIf="nonpreferredAccountSelection" [accountControl]="firstForm.get('pm_account')"></pm-account>
        </div>

        <div *ngIf="showLeaseForm$ | async">
          <div class="section-title color_primary">{{ 'tenant_boarding.carrier_header' | translate }}</div>

          <div class="carrier-margin">
            <pma-carrier
              [formControl]="form.controls.out_of_system_carrier_title"
            ></pma-carrier>
            <!--

              [synonyms]="synonyms"
              (setSynonyms)="setSynonyms($event)"-->
          </div>

          <div class="policy-fields" [hidden]="!form.controls.out_of_system_carrier_title.value">
            <div class="pma-row">
              <div fxFlex="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="" appearance="outline">
                  <mat-label>{{ 'tenant_boarding.policy_start' | translate }}</mat-label>
                  <input matInput required formControlName="effective_date"
                         [matDatepicker]="effectiveDate"
                         (dateInput)="updateDate($event)"
                         (dateChange)="updateDate($event)"
                         [min]="minDate"
                  >
                  <mat-datepicker-toggle matSuffix [for]="effectiveDate"></mat-datepicker-toggle>
                  <mat-datepicker #effectiveDate></mat-datepicker>
                  <mat-error *ngIf="form.controls.effective_date.errors" class="tight" #formError>
                    Please enter a policy start date.
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="" appearance="outline">
                  <mat-label>{{ 'tenant_boarding.expiration' | translate }}</mat-label>
                  <input matInput formControlName="expiration_date"
                         [matDatepicker]="expirationDate"
                         [min]="minDate"
                         [max]="maxDate"
                  >
                  <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
                  <mat-datepicker #expirationDate></mat-datepicker>
                  <mat-error *ngIf="form.controls.expiration_date.errors" class="tight" #formError>
                    Please enter a policy expiration date.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="pma-row">
              <div fxFlex="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%" appearance="outline">
                  <mat-label>{{ 'tenant_boarding.liability_coverage' | translate }}</mat-label>
                  <mat-select [formControl]="liabilityCoverageControl" required>
                    <mat-option *ngFor="let item of liabilityCoverages" [value]="item">
                      {{ item / 100 | currency : 'USD' : 'symbol' : '1.0-0' }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="liabilityCoverageControl.errors" class="tight" #formError>
                    Please enter your liability coverage.
                    <ng-container *ngIf="communityLiabilityCoverageMin">Minimum coverage is {{ communityLiabilityCoverageMin | stripeCurrency }}</ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="contents-coverage-field" fxFlex="50%" fxFlex.lt-sm="100%" appearance="outline" floatLabel="auto">
                  <mat-label class="contents-coverage-field__label">
                    {{ 'tenant_boarding.contents_coverage' | translate }}
                  </mat-label>
                  <input matInput
                         [formControl]="contentsCoverageControl"
                         type="text"
                         mask="separator"
                         thousandSeparator=","
                         maxlength="12"
                         [required]="communityContentsCoverageMin > 0"
                  >
                  <span matTextPrefix>$&nbsp;</span>
                  <mat-hint *ngIf="communityContentsCoverageMin > 0">
                    Minimum of {{ communityContentsCoverageMin | stripeCurrency }} required.
                  </mat-hint>
                </mat-form-field>


              </div>
            </div>

            <div class="pma-row additional-interest">
              <div>
                <mat-checkbox formControlName="additional_interest"
                              color="primary">
                  <span>
                    <span class="tenant-boarding__highlight" *ngIf="!selectedPmAccount; else selectedPmAccountName">
                      {{ additionalInterest.name ? additionalInterest.name : getCommunityAdditionalInterestName }}
                    </span>
                    <ng-template #selectedPmAccountName>{{selectedPmAccount.title}}</ng-template>
                    is listed as an <span class="tenant-boarding__text_underline" (click)="openAdditonalInterestDialog()">additional interest</span> on your policy*
                  </span>
                </mat-checkbox>

              </div>

              <div *ngIf="showAdditionalEmailNotice" class="additional-email">
                <div fxLayout="row"
                     fxLayoutGap="2px"
                     fxLayoutAlign="start start">
                  <div>
                    <mat-icon>warning</mat-icon>
                  </div>
                  <div>
                    <div class="label">
                      Additional Interest Email:
                    </div>
                    <div>
                      If your carrier does not physically mail notices, please be sure to include
                      insurancesupport@secondnature.com along with the additional interest address below.
                    </div>
                  </div>
                </div>
              </div>
              <div class="pma-row additional-interest-address">
                <div>Additional Interest Address:</div>
                <div>
                  <ng-container *ngIf="selectedPmAccount"><div class="tenant-boarding__highlight">{{selectedPmAccount.title}}</div></ng-container>
                  <ng-container *ngIf="!selectedPmAccount">
                    <ng-container *ngIf="additionalInterest.name; else legacyAccountName">
                      <div class="tenant-boarding__highlight">
                        {{ additionalInterest.name }}
                      </div>
                    </ng-container>
                    <ng-template #legacyAccountName>
                      <div class="tenant-boarding__highlight">{{ getAdditionalInterestAddress }}</div>
                    </ng-template>
                  </ng-container>

                  <ng-container *ngIf="!selectedPmAccount; else addressFromAccount">
                    <ng-container *ngIf="additionalInterest.address_part_1 && additionalInterest.address_part_2; else legacyAddress">
                      <div class="tenant-boarding__highlight">{{additionalInterest.address_part_1}}</div>
                      <div class="tenant-boarding__highlight">{{additionalInterest.address_part_2}}</div>
                    </ng-container>
                    <ng-template #legacyAddress>
                      <ng-container *ngIf="[303].includes(branding.id) || isBrandingSecondNature || brandingSlug === 'garden_communities'; else oldAddress">
                        <div class="tenant-boarding__highlight">{{GCPolicyUploadNewAddress[0]}}</div>
                        <div class="tenant-boarding__highlight">{{GCPolicyUploadNewAddress[1]}}</div>
                      </ng-container>
                      <ng-template #oldAddress>
                        <div class="tenant-boarding__highlight">{{GCPolicyUploadDefaultAddress[0]}}</div>
                        <div class="tenant-boarding__highlight">{{GCPolicyUploadDefaultAddress[1]}}</div>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                  <ng-template #addressFromAccount>
                    <ng-container *ngIf="selectedPmAccount.address.street_number &&
                selectedPmAccount.address.street_name &&
                selectedPmAccount.address.city &&
                selectedPmAccount.address.state &&
                selectedPmAccount.address.zip_code
                ">
                      <div class="tenant-boarding__highlight"> {{selectedPmAccount.address.street_number}} {{selectedPmAccount.address.street_name}},</div>
                      <div class="tenant-boarding__highlight">{{selectedPmAccount.address.city}}, {{selectedPmAccount.address.state}} {{selectedPmAccount.address.zip_code}}</div>
                    </ng-container>
                  </ng-template>
                </div>
              </div>
            </div>

            <div matTooltipPosition="above"
                 matTooltip="Only PDF, JPG or PNG file types are accepted"
                 class="upload-area scroll-into-view"
            >

              <app-file-upload label="Upload Declaration Page"
                               [singleFile]="true"
                               [inputFormControl]="form.controls.documents"></app-file-upload>
            </div>
          </div>

          <div [hidden]="!form.controls.out_of_system_carrier_title.value">
            <div class="section-title color_primary">Spouse or Roommate Included On the Policy</div>

            <div class="pma-insured">
              <div>
                How many additional individuals (excluding yourself, such as roommate or spouse) are included on the policy?
              </div>
              <div class="pma-insured__field">
                <mat-form-field appearance="outline">
                  <mat-select [formControl]="form.controls.userCount" (selectionChange)="updateInsuredCount($event)">
                    <mat-option [value]="0">It's just me</mat-option>
                    <mat-option [value]="1">1</mat-option>
                    <mat-option [value]="2">2</mat-option>
                    <mat-option [value]="3">3</mat-option>
                    <mat-option [value]="4">4</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <mat-error *ngIf="form.controls.users.errors?.spouseCount" class="tight">
              You selected "Spouse" more than once.
            </mat-error>
            <mat-error *ngIf="form.controls.users.errors?.duplicateEmails" class="tight">
              Emails listed for individuals on the policy must be unique.
            </mat-error>

            <div class="insured-users">
              <div *ngFor="let user of usersControl.value; let i = index; trackBy:trackUserControl" class="insured-user">
                <pma-insured-user
                  [formControl]="$any(usersControl.at(i))"
                  [applicantEmail]="form.get('email').value"
                  (remove)="onRemoveInsured(i)"
                  [index]="i"
                ></pma-insured-user>
              </div>
            </div>

            <div class="tenant-insured-btn-container">
              <button
                type="button"
                mat-button
                class="mat-btn tenant-insured-btn"
                (click)="pushInsured()">
                + {{ 'residential.add_insured_button' | translate }}
              </button>
            </div>

            <div *ngIf="isBrandingSecondNature && slug !== 'pinata'" style="margin: 15px 0;">
              <mat-checkbox [formControl]="dogCoverageControl"
                            color="primary"
                            labelPosition="before"
              >
                Do you have a dog? If yes, do you have dog bite coverage included in your policy?
              </mat-checkbox>
            </div>
          </div>

          <div *ngIf="form.get('additional_interest').touched && !form.get('additional_interest').value" style="margin: 20px 0 10px;">
            <mat-error class="policy-checkbox-error">
              <div><mat-icon class="error-icon">info</mat-icon></div>
              <div>
                A policy must have
                <ng-container *ngIf="!selectedPmAccount; else selectedPmAccountName">
                  {{ additionalInterest.name ? additionalInterest.name : communityName}}
                </ng-container>
                listed as an additional interest before it can be submitted.
              </div>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="tenant-boarding__button">
        <div *ngIf="policyVerified$ | async" class="tenant-boarding__errors">
          <div *ngIf="(policyUploadLocked$ | async); else notLocked" class="policy-upload-locked">
            We already have all the information we need for this policy. You don't have to provide the information at this moment.
          </div>
          <ng-template #notLocked>
            <div *ngIf="(policyBelongsToOtherUser$ | async)" class="policy-upload-locked">
              <mat-error>A policy with this number has already been uploaded by another user.</mat-error>
            </div>
          </ng-template>
        </div>

        <div *ngIf="(verifyingLeaseOrPolicy$ | async) === false && (continueDisabled$ | async) === false" class="tenant-boarding__text_thin tenant-boarding__text_italic ">
          {{ 'tenant_boarding.i_attest_that_all_of_the_entered' | translate }}
        </div>

        <ng-container *ngIf="(policyBelongsToOtherUser$ | async) === false">
          <div *ngIf="(displayedErrorLinks$ | async)?.length > 0
                  || form.controls.users.errors?.spouseCountf
                  || !this.form.controls.users.valid
                " class="fixed-error"
          >
            Please verify your information. Some fields are invalid or missing.
          </div>
        </ng-container>

        <button mat-button
                #submitButton
                type="submit"
                class="mat-btn warning full-width"
                [disabled]="continueDisabled$ | async"
                [class.spinner]="processing$ | async">
          <ng-container *ngIf="verifyingLeaseOrPolicy$ | async; else buttonTmpl">
            Verifying data...
          </ng-container>
          <ng-template #buttonTmpl>
            {{  isValidInitForm ? ('form.submit' | translate) : 'form.continue' | translate }} &gt;
          </ng-template>
        </button>
      </div>

      <div>
        <div *ngIf="inTenantBoarding" style="margin-top: 20px;" fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">
          <button mat-button
                  type="button"
                  class="mat-btn primary full-width"
                  (click)="changeStep.emit('second')"
                  [disabled]="verifyingLeaseOrPolicy$ | async">
            {{ 'form.back' | translate }}
          </button>
          <button mat-button
                  type="button"
                  class="mat-btn tenant-btn full-width"
                  (click)="navigateToRentersInsurance()"
                  [disabled]="verifyingLeaseOrPolicy$ | async">
            {{ 'tenant_boarding.get_a_renters_insurance_quote' | translate }}
          </button>
          <button *ngIf="isMasterPolicy"
                  mat-button
                  type="button"
                  class="mat-btn tenant-btn full-width"
                  (click)="changeStep.emit('master-policy')"
                  [disabled]="verifyingLeaseOrPolicy$ | async">
            {{ 'tenant_boarding.select_community_master_coverage' | translate }}
          </button>
        </div>
      </div>
    </form>

  </div>
</div>
