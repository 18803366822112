<div class="ui-address-custom">
  <mat-form-field fxFlex="" appearance="outline">
    <mat-label>{{ 'residential.address' | translate }}</mat-label>
    <input matInput
           [formControl]="form.controls['address']"
           [matAutocomplete]="autoInsurable"
           placeholder="Start typing your address..."/>

    <div matPrefix style="margin: 8px">
      <mat-icon>home</mat-icon>
    </div>
    <button *ngIf="loadingAddress" class="text-secondary spinner" mat-icon-button matSuffix type="button">
      <mat-icon>loading</mat-icon>
    </button>
    <mat-autocomplete autoActiveFirstOption #autoInsurable="matAutocomplete" (optionSelected)="selectAddress($event.option.value)" [displayWith]="displayFnInsurable">
      <mat-option *ngFor="let address of addresses" [value]="address">
        {{ address.full }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint
      *ngIf="addresses && addresses.length === 0 && showAddressHint"
      class="mat-hint-address"
    >
      No addresses found, type another address and select it
    </mat-hint>
    <mat-error *ngIf="form.get('address').invalid">
      {{ form.get('address') | errorMsg:'Address' }}
    </mat-error>
  </mat-form-field>
</div>
