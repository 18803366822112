<div class="header-nav">
  <nav class="header-drop-nav">
    <ul>
      <li>
        <div (click)="showMenu()">
          <img src="./assets/images/menu_new.svg" alt="drop menu">
        </div>
      </li>
    </ul>
  </nav>
  <nav class="header-main-nav">
    <ul>
      <li *ngFor="let item of (additionalMenuItems$ | async)">
        <a class="cursor-pointer" (click)="handleCustomMenuClick($event, item.url)">{{ item.title }}</a>
      </li>
    </ul>
  </nav>
</div>

<div class="drop-menu" [class.active]="showMenu$ | async" (click)="onDropMenuClick($event)">
  <div class="drop-menu-content">
    <div class="close-menu">
      <a (click)="closeMenu()">
        <img src="./assets/images/icon-menu-close.svg" alt="close menu">
        <p>{{ 'menu.menu' | translate }}</p>
      </a>
    </div>
    <ul class="drop-menu-list">
      <li>
        <ng-container *ngTemplateOutlet="menuItems; context: { items: mainMenuItems$ | async }"></ng-container>
      </li>
    </ul>
    <div *ngIf="accountMenuInMainMenu$ | async" class="quote-button">
      <button mat-button
              type="submit"
              class="mat-btn warning"
              routerLink="/"
      >
        {{ 'menu.get_a_quote' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #menuItems let-items="items">
  <ul>
    <li *ngFor="let item of items" class="{{ item.class ? item.class : '' }}">
      <a class="cursor-pointer"
         [class.active-link]="curentUrl && curentUrl.includes(item.url)"
         (click)="item.url === '#logout' ? logout($event) : handleCustomMenuClick($event, item.url)">
        {{ item.title }}
      </a>
      <ng-container *ngIf="item.items">
        <ng-container *ngTemplateOutlet="menuItems; context: { items: item.items }"></ng-container>
      </ng-container>
    </li>
  </ul>
</ng-template>
