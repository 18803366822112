import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { environment } from '../../../environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-st-elements',
  templateUrl: './st-elements.component.html',
  styleUrls: ['./st-elements.component.scss']
})
export class StElementsComponent implements AfterViewInit {
  @Input() loading: boolean;
  @Input() hasBackButton = false;
  @Output() cardComplete = new EventEmitter();
  @Output() cardFilled = new EventEmitter();
  @Output() declinePayment = new EventEmitter();
  @ViewChild('cardElement') cardElement: ElementRef;
  stripe; // : stripe.Stripe;
  card;
  cardErrors;
  subscription: Subscription = new Subscription();

  confirmation;
  constructor(private translate: TranslateService) {
    this.subscription.add(this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.mountStripe(params.lang);
    }));
  }

  ngAfterViewInit() {
    const language = this.translate.currentLang;
    this.mountStripe(language);
  }

  mountStripe(language) {
    const style = {
      base: {
        color: '#2f3542',
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '"Poppins", sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#0076bd',
        }
      }
    };

    this.stripe = Stripe(environment.stripePublishableKey, { locale: language });
    const elements = this.stripe.elements();

    this.card = elements.create('card', {style});
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', (val) => {
      if (val.complete === true) {
        this.cardComplete.emit({brand: val.brand});
      }
      const error = val.error;
      this.cardErrors = error && error.message;
    });
  }

  decline() {
    this.declinePayment.emit();
  }

  async handleForm(e) {
    this.cardErrors = null;
    e.preventDefault();

    const { token, error } = await this.stripe.createToken(this.card);
    if (error) {
      this.cardErrors = error.message;
    } else {
      this.cardFilled.emit(token);
    }
  }

}
